import querystring from 'querystring';
import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';
import { convertToDateAndTime, getTrackingTimestamp } from '@utils';
import { Province } from '@templates/inter/interface';

export const trackCarAvailableCard = (cardDetail: Province, dealerName: string, url: string) => {
  const parse = querystring.parse(url);
  const bookingBegin = convertToDateAndTime(parse.booking_begin as string);
  const bookingEnd = convertToDateAndTime(parse.booking_end as string);
  const dealerNameConverted = dealerName.toLowerCase().replace(/ /g, '_');
  const customeDimension = {
    search_pickup_date: bookingBegin.date,
    search_pickup_time: bookingBegin.time,
    search_return_date: bookingEnd.date,
    search_return_time: bookingEnd.time,
    search_dealer_type: `inter`,
    search_dealer_name: `${dealerNameConverted}`,
    search_car_type: 'undefined',
    search_promotion_name: 'undefined',
    search_rental_type: 'undefined',
    sort_by: 'undefined',
    search_time_stamp: getTrackingTimestamp()
  };

  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.Inter),
    event_action: 'search_bookings_success',
    event_label: `dealer_${dealerNameConverted}_${cardDetail.title_th}`,
    ...customeDimension
  });
};
