import { Province } from 'src/templates/inter/interface';
import moment from 'moment-timezone';
import { WEB_SEARCH_LINK } from '@config';
import { trackCarAvailableCard } from './tracking';
import { FunctionComponent } from 'react';
moment.tz('Asia/Bangkok');
interface InterCardProps {
  prefix: string;
  province: Province;
  brandID: string;
  dealerName: string;
}
export const InterCard: FunctionComponent<InterCardProps> = (props) => {
  const composeUrl = (locationID: string) => {
    const { brandID } = props;
    const params = {
      booking_begin: `${moment().add(7, 'days').format('YYYY-MM-DD')} 10:00`,
      booking_end: `${moment().add(9, 'days').format('YYYY-MM-DD')} 10:00`,
      location_id: locationID,
      dealers: brandID
    };
    const parsed = Object.keys(params).reduce((p, c) => (p += `${c}=${params[c]}&`), '?');
    return `${WEB_SEARCH_LINK}/${parsed}`;
  };
  const cardURL = composeUrl(props.province.location_id);
  return (
    <div className="card shadow ">
      <div className="card-body">
        <a href={'#'} onClick={() => trackCarAvailableCard(props.province, props.dealerName, cardURL)}>
          <h4 className="card-title">{props.province[`title_${props.prefix}`]}</h4>
          <div className="divider" />
          <div className="row">
            <div className="col info">
              <p>เริ่มต้น</p>
            </div>
            <div className="col price">
              <h3>{Number(props.province.at_least_price).toLocaleString()}</h3>
              <p>บาท/วัน</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default InterCard;
