import { PureComponent } from 'react';
import { graphql } from 'gatsby';
import { InterPageProps, InterPageState } from './interface';
import { withTrans } from '@shares/locales/hoc';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Context, { ContextType } from '@ui/components/context';
import Layout from '@shares/layout';
import SEO from '@shares/seo';
import SectionSearch from '@shares/search';
import InterCarAvailable from '@pages/interpage/section/car-available';
import SectionServices from '@shares/services';
import SectionFAQ from '@shares/faq';
import { PageSection } from '@utils';

import './style.scss';

class InterPage extends PureComponent<InterPageProps, InterPageState> {
  static contextType = ContextType;
  render() {
    const { prefix } = this.props.pageContext;
    const { frontmatter } = this.props.data.markdownRemark;
    const { edges } = this.props.data.site_description;
    const { short_value_props, hero, inter_vendor_id, sub_vendors, provinces, path, search_snippet } = frontmatter;
    const backgroundSrc = hero;
    const valueProps = short_value_props ? short_value_props : edges[0].node.frontmatter.short_value_props;
    const panels =
      frontmatter.panels &&
      frontmatter.panels.map((item) => ({
        title: item[`title_${prefix}`],
        content: item[`content_${prefix}`],
        show_google_faq: item.show_google_faq
      }));

    const imageLogoData = getImage(frontmatter[`brand_white_image_${prefix}`]);
    return (
      <Context>
        <Layout section={PageSection.Inter} prefix={prefix}>
          <SEO
            prefix={prefix}
            title={frontmatter[`header_${prefix}`]}
            description={frontmatter[`description_${prefix}`]}
            keywords={frontmatter[`keywords_${prefix}`]}
            canonical={frontmatter[`rel_canonical_${prefix}`]}
          />
          <div>
            <SectionSearch
              backgroundSrc={backgroundSrc}
              valueProps={valueProps}
              vendorID={inter_vendor_id}
              t={this.props.t}
              prefix={prefix}
              path={path}
              sectionName={PageSection.Inter}
              vendor={{ vendorID: inter_vendor_id, subVendor: sub_vendors, vendorName: frontmatter[`name_th`] }}
            >
              <div className="heading-text heading-text--inter">
                <h1>
                  ค้นหารถว่าง ราคาถูกที่สุดจาก{' '}
                  {imageLogoData && (
                    <GatsbyImage
                      image={imageLogoData}
                      alt={frontmatter[`brand_white_image_${prefix}_alt`]}
                      className="ml-0 ml-md-3 inter-logo"
                    />
                  )}
                </h1>
              </div>
            </SectionSearch>
            <InterCarAvailable
              provinces={provinces}
              prefix={prefix}
              vendorName={frontmatter[`name_${prefix}`]}
              brandID={inter_vendor_id}
              path={path}
            />

            <SectionServices hideService brandID={inter_vendor_id} prefix={prefix} sectionName={PageSection.Inter} />
            <SectionFAQ
              header="ข้อมูลที่เป็นประโยชน์"
              panels={panels}
              prefix={prefix}
              sectionName={PageSection.Inter}
              searchSnippet={search_snippet}
            />
          </div>
        </Layout>
      </Context>
    );
  }
}

export default withTrans()(InterPage);

export const query = graphql`
  query ($urlPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $urlPath } }) {
      html
      frontmatter {
        name_th
        name_en
        activate
        path
        header_th
        header_en
        sub_title_th
        sub_title_en
        description_th
        description_en
        keywords_th
        keywords_en
        rel_canonical_th
        rel_canonical_en
        brand_white_image_th {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              transformOptions: { fit: CONTAIN }
              height: 50
            )
          }
        }
        brand_white_image_en {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              transformOptions: { fit: CONTAIN }
              height: 50
            )
          }
        }
        brand_image_th {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        brand_image_en {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        brand_white_image_th_alt
        brand_white_image_en_alt
        brand_image_th_alt
        brand_image_en_alt
        props_image_th_alt
        props_image_en_alt
        hero {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        inter_vendor_id
        sub_vendors
        short_value_props {
          content_th
          content_en
        }
        other_brands {
          name_th
          name_en
          url
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          image_alt_th
          image_alt_en
          activate
        }
        provinces {
          title_th
          title_en
          at_least_price
          location_id
          activate
        }
        panels {
          title_th
          title_en
          content_th
          content_en
          show_google_faq
        }
        search_snippet {
          price_per_day
          car
          car_brand
          insurance
          cancellation_policies
        }
      }
    }
    site_description: allMarkdownRemark(filter: { fields: { collection: { eq: "interbrand_description" } } }) {
      edges {
        node {
          id
          frontmatter {
            short_value_props {
              content_th
              content_en
            }
          }
        }
      }
    }
  }
`;
