import InterCard from './card';
import { Province } from 'src/templates/inter/interface';
import { FunctionComponent } from 'react';

interface InterCarAvailableProps {
  prefix: string;
  provinces: Province[];
  vendorName: string;
  brandID: string;
  path: string;
}
export const InterCarAvailable: FunctionComponent<InterCarAvailableProps> = (props: InterCarAvailableProps) => {
  const { prefix, provinces, vendorName, brandID, path } = props;
  return (
    <div className="cities-deal">
      <div className="container">
        <h3>ดีลรถเช่า ให้บริการโดย {vendorName} Car Rental</h3>
        <p>
          จองรถเช่าของ {vendorName} ผ่านทาง Drivehub รับโปรโมชั่นสุดพิเศษ ราคาถูกและยกเลิกฟรีก่อนรับรถ{' '}
          {path && (path === 'true-leasing' || path === 'runway') ? '72' : '48'} ชม.
        </p>
        <div className="wrapper">
          <div className="card-widget">
            <div className="card-container two-columns-fixed ">
              {provinces.map((p) => (
                <InterCard
                  province={p}
                  key={p[`title_${prefix}`]}
                  prefix={prefix}
                  brandID={brandID}
                  dealerName={vendorName}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterCarAvailable;
